import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import InputText from "@sellernote/_sds-v1/src/components/input/InputText";

import useLogin from "./useLogin";
import useLoginForm from "./useLoginForm";
import Styled from "./index.styles";

function LoginForm() {
  const {
    accountId,
    deviceId,
    password,

    handleLoginFormChange,

    isAllInputValid,

    handleLoginSubmit,

    ResponseHandlerOfLogin,
  } = useLoginForm();

  return (
    <>
      <div className="left-side">
        <h2>로그인</h2>

        <div
          onClick={() => {
            throw new ReferenceError("CRA용 ReferenceError 에러 노티 테스트");
          }}
        >
          에러 트리거
        </div>
        <form>
          <InputText
            borderType="filled"
            valueType="string"
            label="ID"
            placeholder="입력해주세요."
            value={accountId}
            setValue={handleLoginFormChange("accountId")}
            isValidated={!!accountId}
          />
          <InputText
            borderType="filled"
            valueType="int"
            label="작업대번호"
            placeholder="입력해주세요."
            value={deviceId}
            setValue={handleLoginFormChange("deviceId")}
            isValidated={!!deviceId}
          />
          <InputText
            borderType="filled"
            valueType="password"
            label="비밀번호"
            placeholder="입력해주세요."
            value={password}
            setValue={handleLoginFormChange("password")}
            isValidated={!!password}
          />
        </form>

        <Button
          theme="primary"
          size="normal"
          label="완료"
          handleClick={handleLoginSubmit}
          disabled={!isAllInputValid}
        />
      </div>

      {ResponseHandlerOfLogin}
    </>
  );
}

function FulFillmentLogo() {
  return (
    <div className="right-side">
      <img
        className="fullfillment"
        src="/images/fullfillment.svg"
        alt="fullfillment"
      />
      <img className="logo" src="/images/login_logo.svg" alt="login_logo" />
    </div>
  );
}

export default function Login() {
  useLogin();

  return (
    <Styled.background>
      <Styled.login>
        <LoginForm />
        <FulFillmentLogo />
      </Styled.login>
    </Styled.background>
  );
}
